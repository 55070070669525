import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './Property.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal } from 'react-bootstrap';
import Apartment9 from '../../assets/img/apartment9.jpg'
import Lottie from 'react-lottie';
import SadEmoji from '../../assets/lottie/sad_emoji.json'
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import loaderImage from '../../assets/img/logo.png'
import { useTheme } from './ThemeContext';


function Property() {
    const [showSidebar, setShowSidebar] = useState(true);
    const { theme, toggleTheme } = useTheme();

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);

    // related to apartment
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // counted house
    const [houseCount, setHouseCount] = useState({});
    const [showModalInactive, setShowModalInactive] = useState(false);

    // delete apartment
    const handleDeleteApartment = () => {

        const accessToken = localStorage.getItem("authTokens");
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;


        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // decoding the token so that i can get the user id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // the passed id from the modal
        const id = selectedApartment.id;
        const updateData = {
            is_visible: false,
        }
        // get the landlord details of the user
        serverUrl.patch(`/landlord/apartment/${id}/`, updateData, config)
            .then((res) => {
                // make the data real time
                setSelectedApartment(null);
                handleCloseModal();
                window.location.reload()
                toast.success("Property Deleted!")

            }, [])
            .catch((error) => {
                console.log(error);
            });



    };

    // pasing the apartment Details to the modal
    const handleOpenModal = apartmentDetail => {
        setSelectedApartment(apartmentDetail);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // apartments of the landlord
    const [landlordApartment, setLandlordApartment] = useState([])

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const apartmentImage = {};
    const [subscriptionStatus, setSubscriptionStatus] = useState([]);
    const [subscription, setSubscription] = useState([]);
    const [subscriptionAmount, setSubscriptionAmount] = useState("");

    const [filterPayments, setFilterPayments] = useState('all')

    // from the localstorage get the logged in user
    useEffect(() => {
        setIsLoading(true)
        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/${landlordId}/filter-invoices/?filter=${filterPayments}`, config)
                        .then((res) => res.data)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });

                });

                Promise.all(landlordApartmentPromises)
                    .then((results) => {
                        // i am merging the sub-arrays into a new array
                        const apartments = results.flat();
                        setLandlordApartment(apartments);

                        //get the details based on the wallet data
                        const walletPromises = apartments.map((apartment) => {
                            return serverUrl
                                .get(`/landlord/subscriptions-invoice/?apartment=${apartment.id}`, config)
                                .then((res) => {
                                    console.log(res.data.results)
                                    const unpaidInvoices = res.data.results.filter((invoice) => invoice.status !== 'paid');
                                    // if (res.data.results.length > 0) {
                                    //     setShowModalInactive(true)

                                    // }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });

                        // get the images of the apartments based on the apartment id 
                        apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/apartment-images/?apartment_id=${apartment.id}`, config)
                                .then((res) => {
                                    res.data.results.forEach((res) => {
                                        apartmentImage[res.apartment] = res.image;
                                    })

                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                        setSelectedApartmentImage(apartmentImage)
                        setIsLoading(false)

                        // the subscription amount
                        const unitAmountSubscriptionPromises = apartments.map(
                            (apartment) => {
                                return serverUrl
                                    .get(
                                        `/landlord/subscription-amount-units/?apartment_id=${apartment.id}`,
                                        config
                                    )
                                    .then((res) => ({
                                        apartmentId: apartment.id,
                                        data: res.data.results[0],
                                        units: res.data.units,
                                    }))
                                    .catch((error) => {
                                        console.log(error);
                                        return { apartmentId: apartment.id };
                                        setIsLoading(false);
                                    });
                            }
                        );
                        // set count of the number of houses that are occupied in that apartment
                        Promise.all(unitAmountSubscriptionPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const houseOccupiedObj = {};
                                results.forEach(({ apartmentId, data }) => {
                                    houseOccupiedObj[apartmentId] = data;
                                });

                                setSubscriptionAmount(houseOccupiedObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        // get the subscription PLAN
                        const subscriptionPromises = apartments.map((apartment) => {
                            return serverUrl
                                .get(
                                    `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                                    config
                                )
                                .then((res) => ({
                                    apartmentId: apartment.id,
                                    subscription: res.data.subscription,
                                }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id };
                                });
                        });

                        // get the subscription status
                        const subscriptionStatusPromises = apartments.map((apartment) => {
                            return serverUrl
                                .get(
                                    `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                                    config
                                )
                                .then((res) => ({
                                    apartmentId: apartment.id,
                                    subscription: res.data.results,
                                }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id };
                                });
                        });

                        // set the response of the subscriptions
                        Promise.all(subscriptionPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const subscriptionObj = {};
                                results.forEach(({ apartmentId, subscription }) => {
                                    subscriptionObj[apartmentId] = subscription;
                                });
                                setSubscriptionStatus(subscriptionObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        // set the response of the subscriptions plan
                        Promise.all(subscriptionStatusPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const subscriptionObj = {};
                                results.forEach(({ apartmentId, subscription }) => {
                                    subscriptionObj[apartmentId] = subscription;
                                });
                                setSubscription(subscriptionObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });


                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false)

                    });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)

            });

    }, []);

    const updateProperties = (filter) => {
        setFilterPayments(filter)

        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
        .then((res) => {
            // get the landlord Id
            const landlordIds = res.data.results.map((landlord) => landlord.id);
            // using the landlord id get the apartments linked to the landlord
            const landlordApartmentPromises = landlordIds.map((landlordId) => {
                return serverUrl.get(`/landlord/${landlordId}/filter-invoices/?filter=${filter}`, config)
                    .then((res) => res.data)
                    .catch((error) => {
                        console.log(error);
                        return [];
                    });

            });

            Promise.all(landlordApartmentPromises)
                .then((results) => {
                    // i am merging the sub-arrays into a new array
                    const apartments = results.flat();
                    setLandlordApartment(apartments);

                    //get the details based on the wallet data
                    const walletPromises = apartments.map((apartment) => {
                        return serverUrl
                            .get(`/landlord/subscriptions-invoice/?apartment=${apartment.id}`, config)
                            .then((res) => {
                                console.log(res.data.results)
                                const unpaidInvoices = res.data.results.filter((invoice) => invoice.status !== 'paid');
                                // if (res.data.results.length > 0) {
                                //     setShowModalInactive(true)

                                // }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });

                    // get the images of the apartments based on the apartment id 
                    apartments.map((apartment) => {
                        return serverUrl.get(`/landlord/apartment-images/?apartment_id=${apartment.id}`, config)
                            .then((res) => {
                                res.data.results.forEach((res) => {
                                    apartmentImage[res.apartment] = res.image;
                                })

                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });
                    setSelectedApartmentImage(apartmentImage)
                    setIsLoading(false)

                    // the subscription amount
                    const unitAmountSubscriptionPromises = apartments.map(
                        (apartment) => {
                            return serverUrl
                                .get(
                                    `/landlord/subscription-amount-units/?apartment_id=${apartment.id}`,
                                    config
                                )
                                .then((res) => ({
                                    apartmentId: apartment.id,
                                    data: res.data.results[0],
                                    units: res.data.units,
                                }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id };
                                    setIsLoading(false);
                                });
                        }
                    );
                    // set count of the number of houses that are occupied in that apartment
                    Promise.all(unitAmountSubscriptionPromises)
                        .then((results) => {
                            // object that gets the number of apartments
                            const houseOccupiedObj = {};
                            results.forEach(({ apartmentId, data }) => {
                                houseOccupiedObj[apartmentId] = data;
                            });

                            setSubscriptionAmount(houseOccupiedObj);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    // get the subscription PLAN
                    const subscriptionPromises = apartments.map((apartment) => {
                        return serverUrl
                            .get(
                                `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                                config
                            )
                            .then((res) => ({
                                apartmentId: apartment.id,
                                subscription: res.data.subscription,
                            }))
                            .catch((error) => {
                                console.log(error);
                                return { apartmentId: apartment.id };
                            });
                    });

                    // get the subscription status
                    const subscriptionStatusPromises = apartments.map((apartment) => {
                        return serverUrl
                            .get(
                                `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                                config
                            )
                            .then((res) => ({
                                apartmentId: apartment.id,
                                subscription: res.data.results,
                            }))
                            .catch((error) => {
                                console.log(error);
                                return { apartmentId: apartment.id };
                            });
                    });

                    // set the response of the subscriptions
                    Promise.all(subscriptionPromises)
                        .then((results) => {
                            // object that gets the number of apartments
                            const subscriptionObj = {};
                            results.forEach(({ apartmentId, subscription }) => {
                                subscriptionObj[apartmentId] = subscription;
                            });
                            setSubscriptionStatus(subscriptionObj);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    // set the response of the subscriptions plan
                    Promise.all(subscriptionStatusPromises)
                        .then((results) => {
                            // object that gets the number of apartments
                            const subscriptionObj = {};
                            results.forEach(({ apartmentId, subscription }) => {
                                subscriptionObj[apartmentId] = subscription;
                            });
                            setSubscription(subscriptionObj);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false)

                });
        })
        .catch((error) => {
            console.log(error);
            setIsLoading(false)

        });
    }

    // filter based on the property location
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = landlordApartment.filter((location) => {
        if (!uniquePropertyLocation.has(location.location)) {
            uniquePropertyLocation.add(location.location);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const filteredData = landlordApartment.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.location !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [landlordApartment]);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleCloseModalInactive = () => {

        setShowModalInactive(false);
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SadEmoji,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }

    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property'>

                {/* inactive subscription modal */}
                <Modal className={`d-none inactiveModal -${theme}`} show={showModalInactive} onHide={handleCloseModalInactive} style={{ marginTop: "7.5rem" }} size='lg'>

                    <Modal.Header closeButton>
                        <Modal.Title>{ }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card className="text-center border-0 position-relative">
                            <div className="card-background"></div>
                            <div className='card-body-content'>
                                <h3 className='text-uppercase'>Your plan is not activated</h3>
                                <p className='w-75 text-center mx-auto'>It seems you have uncleared invoices. In order to continue enjoying our services, please take a moment to settle them.</p>
                                <div className="d-flex justify-content-center">
                                    <Lottie
                                        options={defaultOptions}
                                        height={200}
                                        width={400}
                                    />
                                </div>
                                <p>We value your continued presence and support. We kindly ask you to activate your plan by settling the outstanding invoices.</p>
                                <Button className='w-auto d-flex text-center mb-4 ' style={{ margin: "0 auto" }}>
                                    <Link className='text-white text-decoration-none' to='/subscription'>
                                        ACTIVATE NOW
                                    </Link>
                                </Button>
                            </div>
                        </Card>


                    </Modal.Body>
                </Modal>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>MY PROPERTIES</p>

                </div>


                <Card className='cardProperty'>

                    <Form className="searchProperty">
                        <FormControl type="text" placeholder="Search Property" className="mr-sm-2 rounded-4" onChange={handleSearch} />
                    </Form>

                    <Form.Select size='sm' className='formProperty filterLocationDisplay' value={selectedPropertyLocation} onChange={(e) => setPropertyLocation((e.target.value))} style={{ marginRight: "34rem", width: "40%" }}>
                        <option disabled selected>By Location</option>
                        <option value="">All Properties</option>
                        {uniqueLocation.map((apartment) => (
                            <option value={apartment.location}>{apartment.location}</option>
                        ))}

                    </Form.Select>

                    <Form.Select size='sm' className='formProperty filterLocationDisplay'
                        value={filterPayments} onChange={(e) => updateProperties((e.target.value))

                        } style={{ marginRight: "34rem", width: "40%" }}>
                        <option disabled selected value="">By Payment Status</option>
                        <option value="all">All Properties</option>
                        <option value="paid">Paid</option>
                        <option value="partial">Partially Paid</option>
                        <option value="unpaid">Unpaid</option>


                    </Form.Select>
                    <Form.Select size='sm' className='formProperty d-none'>
                        <option disabled selected>Property Type</option>
                        <option value="">All Houses</option>
                        {landlordApartment.map((apartment) => (
                            <option>{apartment.id}</option>
                        ))}
                    </Form.Select>

                </Card>

                <div>
                    <Button className='propertyButton'>
                        <Link to='/add-apartment' className='text-white text-decoration-none'>Add Property</Link></Button>
                </div>
                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>

                    {displayedItems.map((apartmentDetail, index) =>

                        <Card key={index.id} className='house'>
                            <Card.Body>
                                <Link
                                    style={{ display: "contents" }}
                                    className="text-danger text-decoration-none "
                                    to={
                                        apartmentDetail.num_houses !== 0
                                            ? subscriptionStatus[apartmentDetail.id] &&
                                                subscriptionStatus[apartmentDetail.id] === "No subscription found"
                                                ? `/new-plan/${apartmentDetail.id}`
                                                : subscription[apartmentDetail.id] &&
                                                    subscription[apartmentDetail.id].length !== 0 &&
                                                    subscription[apartmentDetail.id][0].status !== "paid"
                                                    ? `/wallet-data/${apartmentDetail.id}`
                                                    : `/selected-property/${apartmentDetail.id}`
                                            : `/selected-property/${apartmentDetail.id}`
                                    }
                                >
                                    <div className="image-container" style={{ filter: subscriptionStatus[apartmentDetail.id] === "No subscription found" || (subscription[apartmentDetail.id] && subscription[apartmentDetail.id].length !== 0 && subscription[apartmentDetail.id][0].status !== "paid") ? "grayscale(100%)" : "none" }}>
                                        {selectedApartmentImage[apartmentDetail.id] && selectedApartmentImage[apartmentDetail.id] ?
                                            <Image src={selectedApartmentImage[apartmentDetail.id]} className="property-grid" alt={index}></Image> :
                                            <Image
                                                src={backgroundImageApartment[apartmentDetail.id]}
                                                className="property-grid"
                                            />
                                        }

                                        <div className="badge">{apartmentDetail.num_houses} Units</div>

                                        <p className='text-muted float-left'>{apartmentDetail.location}</p>
                                        <br></br>
                                    </div>
                                    <p className='houseName' style={{ clear: "left" }}>{apartmentDetail.name}</p>
                                    <p></p>
                                </Link>


                                <div className='buttonsProperties'>

                                    <Link
                                        className={`text-danger text-decoration-underline ${subscriptionStatus[apartmentDetail.id] === "No subscription found" || (subscription[apartmentDetail.id] && subscription[apartmentDetail.id].length !== 0 && subscription[apartmentDetail.id][0].status !== "paid") ? "text-muted" : ""}`}
                                        to={
                                            apartmentDetail.num_houses !== 0
                                                ? subscriptionStatus[apartmentDetail.id] &&
                                                    subscriptionStatus[apartmentDetail.id] === "No subscription found"
                                                    ? `/new-plan/${apartmentDetail.id}`
                                                    : subscription[apartmentDetail.id] &&
                                                        subscription[apartmentDetail.id].length !== 0 &&
                                                        subscription[apartmentDetail.id][0].status !== "paid"
                                                        ? `/wallet-data/${apartmentDetail.id}`
                                                        : `/selected-property/${apartmentDetail.id}`
                                                : `/selected-property/${apartmentDetail.id}`
                                        }
                                    >
                                        View Property <i className='bi bi-arrow-right'></i>
                                    </Link>
                                    <button
                                        className={`icon-button text-white btn ${subscriptionStatus[apartmentDetail.id] === "No subscription found" || (subscription[apartmentDetail.id] && subscription[apartmentDetail.id].length !== 0 && subscription[apartmentDetail.id][0].status !== "paid") ? "btn-secondary bg-secondary text-secondary" : "btn-danger bg-danger text-white"}`}
                                        onClick={subscriptionStatus[apartmentDetail.id] === "No subscription found" || (subscription[apartmentDetail.id] && subscription[apartmentDetail.id].length !== 0 && subscription[apartmentDetail.id][0].status !== "paid") ? null : () => handleOpenModal(apartmentDetail)}
                                    >
                                        <i className='bi bi-trash'></i>
                                    </button>


                                    <Modal show={showModal} onHide={handleCloseModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Property</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Do you want to delete the property?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="success" onClick={handleCloseModal}>
                                                No
                                            </Button>
                                            <Button apartmentId={apartmentDetail.id} variant="danger" onClick={handleDeleteApartment}>
                                                Yes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>


                            </Card.Body>
                        </Card>
                    )}


                </div>

                {landlordApartment.length > 8 ?
                    <div className="pagination" style={{ marginLeft: "14rem" }}>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                    :
                    <>
                    </>}


            </div>

        </div>
    )
}

export default Property




