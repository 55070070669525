import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { serverUrl } from "../../serverUrl";


const TenantHistoryModal = ({ tenantId }) => {
    const [show, setShow] = useState(false);
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleShow = async () => {
        setShow(true);
        setLoading(true);

        try {
            // Replace with your API endpoint
            const response = await serverUrl.get(`/tenant/tenant-history/${tenantId}/`);
            setHistory(response.data.history);
        } catch (error) {
            console.error("Error fetching tenant history:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => setShow(false);

    return (
        <>
            <Button style={{ background: "#34495e", border: "0" }} onClick={handleShow}>
                History
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Tenant History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <p>Loading...</p>
                    ) : history.length === 0 ? (
                        <p>No history available for this tenant.</p>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>House Number</th>
                                    <th>Date In</th>
                                    <th>Date Out</th>
                                    <th>Number of Days</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {history.map((record, index) => (
                                    <tr key={index}>
                                        <td>{record.house_number}</td>
                                        <td>{record.date_in}</td>
                                        <td>{record.date_out || "Active"}</td>
                                        <td>{record.number_of_days}</td>
                                        <td style={{
                                            color: record.status === 'active' ? 'green' : 'red',
                                            textAlign: 'center'
                                        }}>
                                            {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TenantHistoryModal;
