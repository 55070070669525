/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Table, Form, FormControl } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Header from "./Header";
import SideBar from "./SideBar";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import "./Dashboard.scss";

export default function DefaultersLandlord() {
    const navigate = useNavigate();
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(true);
    const [defaulters, setDefaulters] = useState([]);
    const [apartmentFilter, setApartmentFilter] = useState("all");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [sortType, setSortType] = useState("tenant_name");
    const [sortOrder, setSortOrder] = useState("asc");

    const itemsPerPage = 10;

    // Get access token
    const accessToken = localStorage.getItem("authTokens");
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = { headers: { Authorization: `Bearer ${access}` } };

    useEffect(() => {
        const token = localStorage.getItem("authTokens");
        if (!token) {
            navigate("/login");
            return;
        }
    
        const parsedTokens = JSON.parse(token);
        const decodedToken = jwt_decode(token);
        const userId = decodedToken.user_id;
        const queryParams = new URLSearchParams(location.search);
        const apartmentId = queryParams.get("apartment_id");
    
        serverUrl
            .get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                const landlordId = res.data.results[0].id;
                const endpoint = `/landlord/get_defaulters_list/?landlord_id=${landlordId}`;
                serverUrl
                    .get(endpoint, config)
                    .then((res) => {
                        const fetchedDefaulters = res.data.defaulters_list;
    
                        if (apartmentId) {
                            // Filter the list to include only items with the given apartment_id
                            const filteredDefaulters = fetchedDefaulters.filter(
                                (defaulter) => defaulter.apartment_id === parseInt(apartmentId, 10)
                            );
                            setDefaulters(filteredDefaulters);
                        } else {
                            setDefaulters(fetchedDefaulters);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => console.log(error));
    }, [navigate]);
    

const handleSearch = (event) => setSearchQuery(event.target.value);
const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
};
const handleApartmentFilterChange = (event) => setApartmentFilter(event.target.value);

// Get unique apartments from defaulters list
const uniqueApartments = [...new Set(defaulters.map((item) => item.apartment))];

const sortedData = defaulters.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    return sortOrder === "asc" ? (sortValueA > sortValueB ? 1 : -1) : (sortValueA < sortValueB ? 1 : -1);
});

const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    const matchesSearch = searchableFields.includes(searchQuery.toLowerCase());
    const matchesApartment = apartmentFilter === "all" || item.apartment === apartmentFilter;

    return matchesSearch && matchesApartment;
});

const totalPages = Math.ceil(filteredData.length / itemsPerPage);
const startIndex = currentPage * itemsPerPage;
const endIndex = startIndex + itemsPerPage;
const displayedItems = filteredData.slice(startIndex, endIndex);

const handlePrevPage = () => setCurrentPage((prev) => prev - 1);
const handleNextPage = () => setCurrentPage((prev) => prev + 1);

const getSortIcon = (field) => {
    if (sortType === field) {
        return sortOrder === "asc" ? (
            <i className="bi bi-chevron-up"></i>
        ) : (
            <i className="bi bi-chevron-down"></i>
        );
    }
    return <i className="bi bi-arrow-down-up"></i>;
};

return (
    <div>
        <Header showSidebar={showSidebar} onButtonClick={() => setShowSidebar(!showSidebar)} />
        {showSidebar && <SideBar />}
        <div className="dashboard mt-5 p-5" style={{ marginLeft: "14rem" }}>
            <h5>List of Defaulters</h5>
            <div className="filters d-flex justify-content-between mb-3">
                <Form className="searchBar">
                    <FormControl
                        type="text"
                        placeholder="Search"
                        className="bg-white border border-secondary"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </Form>
                <div className="d-flex">
                    <Form.Select value={apartmentFilter} onChange={handleApartmentFilterChange} className="w-auto ms-3">
                        <option value="all">All Apartments</option>
                        {uniqueApartments.map((apartment, index) => (
                            <option key={index} value={apartment}>{apartment}</option>
                        ))}
                    </Form.Select>
                </div>
            </div>
            <Table>
                <thead className="mt-2">
                    <tr style={{ textAlign: "left" }}>
                        <th onClick={() => handleSort("tenant_name")}>Tenant Name {getSortIcon("tenant_name")}</th>
                        <th onClick={() => handleSort("tenant_phone")}>Tenant Phone {getSortIcon("tenant_phone")}</th>
                        <th onClick={() => handleSort("house_name")}>House Number {getSortIcon("house_name")}</th>
                        <th onClick={() => handleSort("apartment")}>Apartment {getSortIcon("apartment")}</th>
                        <th onClick={() => handleSort("balance")}>Amount Due {getSortIcon("balance")}</th>
                    </tr>
                </thead>
                <tbody>
                    {displayedItems.map((invoice, index) => (
                        <tr key={index} style={{ textAlign: "left" }}>
                            <td className="text-capitalize">{invoice.tenant_name}</td>
                            <td className="text-capitalize">{invoice.tenant_phone}</td>
                            <td>{invoice.house_name}</td>
                            <td>{invoice.apartment}</td>
                            <td>KSH. {parseInt(invoice.balance)?.toLocaleString("en-us")}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="pagination">
                <Button onClick={handlePrevPage} disabled={currentPage === 0}>
                    &lt;
                </Button>
                <span>
                    {startIndex + 1}-{Math.min(endIndex, filteredData.length)} of {filteredData.length}
                </span>
                <Button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                    &gt;
                </Button>
            </div>
        </div>
    </div>
);
}
