import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import './HistoryReport.scss';
import { useParams } from 'react-router-dom';
import { Button, Table, Form, FormControl, Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';
// import { PDFExport } from '@progress/kendo-react-pdf';
// import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// auth imports
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../../serverUrl';
import { toast } from 'react-toastify';

function InvoiceReport() {
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    // getting the id and finding the apparment details
    const { id } = useParams();
    // house selected
    const [houseSelected, setHouseSelected] = useState([]);
    const [houseSelectedReport, setHouseSelectedReport] = useState([]);
    const [houseSelectedReportPaid, setHouseSelectedReportPaid] = useState([]);
    // loading status
    const [isLoading, setIsLoading] = useState(true);
    const [occupancySet, setOccupancy] = useState({})
    const [tenantsInvoice, setTenantInvoice] = useState({})
    const tenantsNames = {}

    // get the list of the houses

    const accessToken = localStorage.getItem('authTokens');
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    // filter based on the tenant name
    const [selectedTenant, setTenantSelected] = useState(null);

    // Create a Set to store the tenant 
    const uniqueTenantSelected = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueTenant = houseSelectedReport.filter((tenant) => {
        if (!uniqueTenantSelected.has(tenant.user)) {
            uniqueTenantSelected.add(tenant.user);
            return true;
        }
        return false;
    });
    const [showModalPayment, setShowModalPayment] = useState(false)

    const handleOpenModalPayment = () => {
        setShowModalPayment(true)
    }
    const handleCloseModalPayment = () => {
        setShowModalPayment(false)
    }
    const [landlordApartment, setLandlordApartment] = useState([])
    const [selectedApartmentHouse, setSelectedApartmentHouse] = useState(null);

    // get the house selected by user
    useEffect(() => {
        setIsLoading(true);

        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                Promise.all(landlordApartmentPromises)
                    .then((results) => {
                        // i am merging the sub-arrays into a new array
                        const apartments = results.flat();
                        setLandlordApartment(apartments);
                        // getting the houses in the apartment
                        const houseCountPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, houses: res.data.results }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, houses: [] };
                                });
                        });


                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false)

                    });
            })
            .catch((error) => {
                console.log(error);
            });

        // get house selected by user
        serverUrl.get(`/landlord/house/${id}/`, config)
            .then((res) => {
                setHouseSelected(res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        // get the tenant of the house
        serverUrl.get(`/tenant/occupancy/?house_id=${id}`, config)
            .then((res) => {
                const tenantOccupancy = {};
                res.data.results.forEach((occupancy) => {
                    if (occupancy.status === "active") {
                        tenantOccupancy[id] = occupancy;
                        setOccupancy(occupancy);
                    }
                });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });


    }, [id])


    useEffect(() => {
        serverUrl.get(`/tenant/tenant/${occupancySet.tenant}`, config)
            .then((res) => {
                // get the house report of the selected house
                // first check if the location is tenant report or house selected
                const pathname = window.location.pathname;
                // const currentUrl = window.location.pathname;
                const hash = window.location.hash;
                const currentUrl = hash.substring(1);
                // Split the URL by '/'
                const urlParts = currentUrl.split('/');
                // Get the last part of the URL
                const lastPart = urlParts[urlParts.length - 2];

                // to check on this later 
                // Check if the last part is "tenant-report"
                // if (lastPart === 'tenant-report') {
                //     // The URL contains "tenant-report"

                //     serverUrl.get(`/landlord/tenant-invoice/?house_id=${id}`, config)
                //         .then((res) => {
                //             setHouseSelectedReport(res.data.results);
                //             const promises = res.data.results.map((tenant) => {
                //                 return serverUrl
                //                     .get(`/users/users-details/${tenant.user}/`, config)
                //                     .then((res) => {
                //                         tenantsNames[tenant.user] = res.data.full_name;
                //                     })
                //                     .catch((error) => {
                //                         console.log(error);
                //                     });
                //             });

                //             Promise.all(promises).then(() => {
                //                 // After all tenant details are fetched, update the tenantsInvoice state
                //                 setTenantInvoice(tenantsNames);
                //             });
                //             // setHouseSelectedReportPaid(res.data.total_paid);
                //         })
                //         .catch((error) => {
                //             console.log(error);
                //         });
                // } else {
                //     // The URL does not contain "tenant-report"
                //     serverUrl.get(`/landlord/tenant-invoice/?house_id=${id}&user_id=${res.data.user}`, config)
                //         .then((res) => {
                //             setHouseSelectedReport(res.data.results);

                //             // setHouseSelectedReportPaid(res.data.total_paid);
                //         })
                //         .catch((error) => {
                //             console.log(error);
                //         });
                // }


                serverUrl.get(`/landlord/tenant-invoice/?house_id=${id}`, config)
                    .then((res) => {
                        setHouseSelectedReport(res.data.results);
                        const promises = res.data.results.map((tenant) => {
                            return serverUrl
                                .get(`/users/users-details/${tenant.user}/`, config)
                                .then((res) => {
                                    tenantsNames[tenant.user] = res.data.full_name;
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });

                        Promise.all(promises).then(() => {
                            // After all tenant details are fetched, update the tenantsInvoice state
                            setTenantInvoice(tenantsNames);
                        });
                        // setHouseSelectedReportPaid(res.data.total_paid);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [occupancySet.tenant, setTenantInvoice])

    // declare the selected house
    const selectedUnit = houseSelected;
    const idHouse = parseInt(id);

    // download all invoices 
    function handleDownload() {
        try {
            setIsLoading(true);
            serverUrl
                .get(`/tenant/generate_pdf_invoice/?house_id=${id}&download=true`, config)
                .then((res) => {
                    // create a download url for the pdf and then automatically download the pdf
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'house_invoice_report.pdf');
                    document.body.appendChild(link);
                    link.click();
                    // when pdf downloaded succesffully
                    toast.success('Successfully Downloaded Your House Invoice Report');
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) { }
    }

    // download one invoice
    function handleDownloadInvoice(invoiceId) {
        try {
            setIsLoading(true);
            serverUrl
                .get(`/tenant/generate_pdf_invoice/?invoice_id=${invoiceId}&download=true`, config)
                .then((res) => {
                    // create a download url for the pdf and then automatically download the pdf
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'invoice_report.pdf');
                    document.body.appendChild(link);
                    link.click();
                    // when pdf downloaded succesffully
                    toast.success('Successfully Downloaded Your Invoice Report');
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) { }
    }

    // get invoice for one invoice display
    const handleViewPDFInvoice = (invoiceId) => {
        setIsLoading(true);
        serverUrl
            .get(`/tenant/generate_pdf_invoice/?invoice_id=${invoiceId}&download=true`, config)
            .then((res) => {
                const pdfData = res.data;
                const pdfUrl = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
                window.open(pdfUrl, '_blank');
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }
    function getMonthNumber(month) {
        const months = {
            january: 1,
            february: 2,
            march: 3,
            april: 4,
            may: 5,
            june: 6,
            july: 7,
            august: 8,
            september: 9,
            october: 10,
            november: 11,
            december: 12
        };

        return months[month];
    }

    // searching function
    const [sortType, setSortType] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSort = (sortField) => {
        setSortType(sortField);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null);
    };

    const sortedData = houseSelectedReport.sort((a, b) => {
        const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === 'asc') {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        // Filter by selected rent
        if (selectedTenant && item.user !== selectedTenant) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    // const displayedItems = filteredData
    const handleRowClick = (item) => {
        setSelectedRow(item);
    };

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // const displayedItems = filteredData

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // add payments
    // handle when the house is selected
    const [houseSelectedId, setHouseSelectedId] = useState("");
    const [invoices, setInvoices] = useState([])
    const [invoiceSelectedId, setInvoiceSelectedId] = useState("");
    const [houseSelectedName, setHouseSelectedName] = useState("")
    const [apartmentHouse, setApartmentHouse] = useState([]);
    const [selectedApartmentId, setSelectedApartmentId] = useState("");
    const [selectedUtilities, setSelectedUtilities] = useState([])
    const [selectedHouseId, setSelectedHouseId] = useState(null);

    const monthNames = [
        "january", "february", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december"
    ];
    function handleApartmentSelect(selectedApartmentId) {
        setSelectedApartmentId(selectedApartmentId)
        // get the houses to be listed when apartment is selected
        serverUrl
            .get(`/landlord/react-house-apartment-tenant/?apartment_id=${selectedApartmentId}`, config)
            .then((res) => {
                // Filter houses with occupancy status "vacant"
                const vacantHouses = res.data.results.filter((house) => house.status === 'occupied');

                // Set the filtered vacant houses in the state
                setApartmentHouse(vacantHouses);
            })
            .catch((error) => {
                // Handle the error, e.g., display an error message to the user
                console.log("Error fetching apartment data:", error);
                // You can also update state to indicate the error, if needed
            });

        // get the utilities to be displayed for that apartment
        serverUrl.get(`/landlord/expense/?apartment_id=${selectedApartmentId}`, config)
            .then((res) => {
                setSelectedUtilities(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    function handleHouseSelect(selectedHouseid) {
        setHouseSelectedId(selectedHouseid)

        // Find the selected house object based on its ID
        const selectedHouse = apartmentHouse.find((house) => house.house_id === parseInt(selectedHouseid));

        // Log the ID and house_number
        if (selectedHouse) {
            setHouseSelectedName(selectedHouse.house)
        }


        serverUrl.get(`/landlord/tenant-invoice/?house_id=${selectedHouseid}&reference=${selectedCategory}`, config)
            .then((res) => {
                // Filter invoices with status not equal to "paid"
                const unpaidInvoices = res.data.results.filter(invoice => invoice.status !== "paid");
                setInvoices(unpaidInvoices);
            })
            .catch((error) => {
                console.log(error);
            });

        // console.log('house selected', selectedHouseid)
    }
    // form data for tenant details ratings
    const [formDataPayment, setFormDataPayment] = useState({
        apartment: "",
        house_number: "",
        category: '',
        utility_type: '',
        source: '',
        amount: '',
        paid_date: '',
        phone_number: ''
    });
    // onchange functionality on rating tenant
    const handleChangePayment = (event) => {
        const { name, value } = event.target;
        let errorMessage = "";

        if (name === "phone_number") {
            // Check if the entered number starts with '+254' and has at most 12 digits
            if (!value.startsWith("254") || value.length > 13 || isNaN(value.slice(4))) {
                errorMessage = "Invalid phone number format. Please enter a valid number starting with '254' and followed by up to 9 digits.";
            }
        }

        setFormDataPayment({
            ...formDataPayment,
            [name]: value,
            errorMessage: errorMessage
        });

    }



    // handle when the utility is selected
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedUtility, setSelectedUtility] = useState("");

    const handleCategorySelect = (e) => {
        setSelectedCategory(e.target.value);
        setSelectedUtility("");
    };

    // handle when the type of payment is selected
    const [selectedMethod, setSelectedMethod] = useState("");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

    const handlePaymentSelect = (e) => {
        setSelectedMethod(e.target.value);
        setSelectedPaymentMethod("");
    };


    // the invoice selected
    function handleInvoiceSelect(selectedInvoiceid) {
        setInvoiceSelectedId(selectedInvoiceid)

    }

    // save the data to the backend on the payment
    function handleSavePayment(e) {
        // prevent form from redirecting to another page
        e.preventDefault();
        const getCurrentTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');

            return `${year}${month}${day}${hours}${minutes}${seconds}`;
        };
        // Add current time (hours, minutes, seconds)
        const currentTime = getCurrentTime();
        // get the details of the tenant
        const tenant = apartmentHouse.filter((house) => house.house === houseSelectedName && house.apartment === selectedApartmentId)
        const fullName = tenant[0].tenant;
        const firstName = fullName.split(' ')[0];

        const formData = {
            transcation_type: selectedMethod,
            transcation_id: selectedMethod + houseSelectedName,
            bill_ref_number: houseSelectedName,
            apartment: selectedApartmentId,
            transcation_time: currentTime,
            transcation_amount: formDataPayment.amount,
            phone_number: formDataPayment.phone_number,
            first_name: firstName,
        };


        // post the data to the ctob
        serverUrl.post(`/landlord/c2btranscation/`, formData, config)
            .then((res) => {
                setShowModalPayment(false)
                window.location.reload()
                const [month, year] = invoiceSelectedId.split('-');
                serverUrl.get(`/landlord/reconcile_transactions/?c2btransaction_id=${res.data.id}&house_id=${houseSelectedId}&payment_category=${selectedCategory}&expense_id=${selectedUtility}&invoice_month=${month}&invoice_year=${year}`, config)
                    .then((res) => {
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                toast.success('Succesfully added the payment!')

            })
            .catch((error) => {
                console.log(error)
            })

    }

    const exportCSV = () => {
        const csvData = [
            ['Amount', 'Status', 'Payment Category', 'Month', 'Year', 'Date'],
            ...houseSelectedReport.map((item) => [item.amount, item.status, item.reference, item.month, item.year, new Date(item.updated_at).toISOString().split('T')[0]]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'data.csv');
    };

    // const pdfExportRef = useRef();

    // const exportPDF = () => {
    //     pdfExportRef.current.save();
    // };

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }
    return (
        <>
            {selectedUnit.id === idHouse ? (
                <div className="HistoryReport" >
                    {/* list of the tenants of the apartment and payment details */}
                    <div className="tasks ">
                        <h2>Invoice report - {selectedUnit.house_number} </h2>
                        <p>
                            Current Status :
                            {selectedUnit.occupancy_status === 'occupied' ? (
                                <b className="text-success text-uppercase"> {selectedUnit.occupancy_status}</b>
                            ) : (
                                <b className="text-danger text-uppercase">{selectedUnit.occupancy_status}</b>
                            )}
                        </p>
                        <div className="d-flex justify-content-between">
                            <div>
                                <Button className="btn btn-success text-white me-3" onClick={exportCSV}>
                                    {' '}
                                    EXCEL <i className="bi bi-file-earmark-excel "></i>
                                </Button>
                                <Button className="btn btn-danger text-white" onClick={handleDownload}>
                                    PDF <i className="bi bi-file-pdf-fill"></i>
                                </Button>

                            </div>
                            <Form className="searchBar">
                                <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                            </Form>
                        </div>

                        <Form.Select size='sm' className='formProperty d-none' value={selectedTenant} onChange={(e) => setTenantSelected((e.target.value))}>
                            <option disabled selected>Filter By:</option>
                            <option value="">All Tenants</option>
                            {houseSelectedReport.map((users) => (
                                <option value={users.user}>{tenantsInvoice[users.user]}</option>
                            ))}
                        </Form.Select>

                        <Table className="table mt-3">
                            <thead className="underline">
                                <tr>
                                <th onClick={() => handleSort('id')}>
                                        Invoice Id
                                    </th>
                                    <th onClick={() => handleSort('amount')}>
                                        Amount
                                    </th>
                                    <th onClick={() => handleSort('status')}>
                                        Status
                                    </th>
                                    <th onClick={() => handleSort('reference')}>
                                        Reference
                                    </th>
                                    <th onClick={() => handleSort('month')}>
                                        Month
                                    </th>
                                    <th onClick={() => handleSort('year')}>
                                        Year
                                    </th>
                                    <th onClick={() => handleSort('updated_at')}>
                                        Date
                                    </th>
                                    <th>Invoice </th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedItems
                                    .sort((a, b) => {
                                        // Sort by year first in descending order
                                        if (b.year !== a.year) {
                                            return b.year - a.year;
                                        }
                                        // If years are the same, sort by month in descending order
                                        return getMonthNumber(b.month) - getMonthNumber(a.month);
                                    })
                                    .map((houseHistory) => (
                                        <tr key={houseHistory.id}>
                                            <td>INV {houseHistory.id}</td>
                                            <td>Ksh. {houseHistory.amount}</td>
                                            <td>{houseHistory.status}</td>
                                            {houseHistory.reference === "expense" ?
                                                <td>utility</td>
                                                :
                                                <td>{houseHistory.reference}</td>
                                            }
                                            <td>{houseHistory.month}</td>
                                            <td>{houseHistory.year}</td>
                                            <td>{new Date(houseHistory.updated_at).toISOString().split('T')[0]}</td>

                                            <td>
                                                <Button
                                                    className="btn btn-primary text-white btn-sm"
                                                    onClick={() => handleViewPDFInvoice(houseHistory.id)}
                                                >
                                                    View <i className="bi bi-eye-fill"></i>
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    className="btn btn-danger text-white btn-sm"
                                                    onClick={() => handleDownloadInvoice(houseHistory.id)}
                                                >
                                                    PDF <i className="bi bi-file-pdf-fill"></i>
                                                </Button>
                                            </td>
                                            {houseHistory.status === "unpaid" ?
                                                <td>
                                                    <Button
                                                        className="btn btn-warning text-white btn-sm"
                                                        onClick={handleOpenModalPayment}>
                                                        Add Payment
                                                    </Button>
                                                </td>


                                                :
                                                <></>}
                                        </tr>
                                    ))}

                            </tbody>
                            <Modal show={showModalPayment} onHide={handleCloseModalPayment}>
                                <Modal.Header className='bg-primary text-white' closeButton>
                                    <Modal.Title>Add Payment</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form onSubmit={handleSavePayment}>
                                        <Form.Select
                                            size='sm'
                                            className='formProperty'
                                            value={selectedApartmentHouse}
                                            onChange={(e) => handleApartmentSelect(e.target.value)}

                                        >
                                            <option value="">Select Apartment</option>
                                            {landlordApartment.map((landlord) => (
                                                <option key={landlord.id} value={landlord.id}>
                                                    {landlord.name}
                                                </option>
                                            ))}
                                        </Form.Select>

                                        <Form.Select
                                            size='sm'
                                            className='formProperty'
                                            value={selectedHouseId} // Update this to the selected house ID
                                            onChange={(e) => handleHouseSelect(e.target.value)} // Update the selected house ID

                                        >
                                            <option value="">Select House</option>
                                            {apartmentHouse.map((landlord) => (
                                                <option key={landlord.house_id} value={landlord.house_id}>
                                                    {landlord.house} - {landlord.tenant}
                                                </option>
                                            ))}
                                        </Form.Select>

                                        <Form.Select
                                            size='sm'
                                            className='formProperty'
                                            value={selectedMethod}
                                            onChange={handlePaymentSelect}

                                        >
                                            <option value="" disabled>Select Payment Method</option>
                                            <option value="cash">Cash</option>
                                            <option value="bank">Bank Payment</option>
                                            <option value="direct-to-phone">Direct To Phone</option>
                                        </Form.Select>


                                        <Form.Select
                                            size='sm'
                                            className='formProperty'
                                            value={selectedCategory}
                                            onChange={handleCategorySelect}

                                        >
                                            <option value="" disabled>Select Category</option>
                                            <option value="expense">Utility</option>
                                            <option value="rent">Rent</option>
                                        </Form.Select>

                                        {selectedCategory === "expense" && (
                                            <Form.Select
                                                size='sm'
                                                className='formProperty'
                                                value={selectedUtility}
                                                onChange={(e) => setSelectedUtility(e.target.value)}
                                                style={{ marginTop: "10px" }}
                                            >
                                                <option value="" disabled selected>Select Utility</option>
                                                {selectedUtilities.map((utility) => (
                                                    <option key={utility.id} value={utility.id}>
                                                        {utility.title}
                                                    </option>
                                                ))}

                                            </Form.Select>

                                        )}

                                        <Form.Select
                                            size="sm"
                                            className="formProperty"
                                            value={invoiceSelectedId}
                                            onChange={(e) => handleInvoiceSelect(e.target.value)}
                                        >
                                            <option value="">Month Invoice Payment</option>
                                            {monthNames.map((month, index) => {
                                                const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);
                                                const currentYear = new Date().getFullYear(); // Get the current year
                                                const hasInvoiceForMonth = invoices.some(invoice => invoice.month.toLowerCase() === month);

                                                return (
                                                    <>
                                                        {hasInvoiceForMonth ? (
                                                            invoices.map((invoice) => (
                                                                invoice.month.toLowerCase() === month ? (
                                                                    <option
                                                                        key={invoice.id}
                                                                        value={`${invoice.month}-${invoice.year}`} // Include both month and year
                                                                        style={{ color: invoice.status === "unpaid" ? 'red' : 'orange' }}
                                                                    >
                                                                        {invoice.month} - {invoice.year} - {invoice.status} - {invoice.amount}
                                                                    </option>
                                                                ) : null
                                                            ))
                                                        ) : (
                                                            <option key={month} value={`${month}-unknown`}>
                                                                {formattedMonth} - {currentYear}
                                                            </option>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </Form.Select>


                                        <input
                                            className="input-field"
                                            type="text"
                                            name="phone_number"
                                            placeholder='Enter Phone Number'
                                            value={formDataPayment.phone_number}
                                            onChange={handleChangePayment}
                                            required
                                            style={{ borderRadius: '5px', border: '1px solid lightgray', marginTop: '1rem', outline: '0' }}
                                        />
                                        {formDataPayment.errorMessage && (
                                            <p style={{ color: 'red' }}>{formDataPayment.errorMessage}</p>
                                        )}
                                        <div className="input-container">
                                            <div className="placeholder-text mt-3 mb-2" style={{ fontSize: '0.9rem' }}>Choose Payment Date</div>

                                            <input
                                                className="input-field"
                                                type="date"
                                                name="paid_date"
                                                value={formDataPayment.paid_date}
                                                onChange={handleChangePayment}
                                                required
                                                style={{ borderRadius: '5px', border: '1px solid lightgray', outline: '0' }}
                                            />
                                        </div>
                                        <input
                                            className="input-field"
                                            type="number"
                                            name="amount"
                                            placeholder='Enter Amount'
                                            value={formDataPayment.amount}
                                            onChange={handleChangePayment}
                                            required
                                            style={{ borderRadius: '5px', border: '1px solid lightgray', marginTop: '1rem', outline: '0' }}
                                        />
                                        <Button className='loginBtn mt-3 ms-4' type='submit'>Save</Button>
                                    </Form>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button variant='secondary' onClick={handleCloseModalPayment}>
                                        Close
                                    </Button>
                                </Modal.Footer> */}
                            </Modal>
                            <div className="bottomDataHouseReport">
                                <p className="text-center mt-2"> total {houseSelectedReportPaid}</p>
                            </div>
                        </Table>
                        <div className="pagination">
                            <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                &lt;
                            </button>
                            <span>{`${startIndex + 1}-${endIndex} of ${houseSelectedReport.length}`}</span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                &gt;
                            </button>
                        </div>
                    </div>

                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default InvoiceReport