import React, { useState, useEffect } from 'react'
import Header from './Header';
import SideBar from './SideBar';
import './SelectedApartmentRepairs.scss'
import { useParams, Link } from 'react-router-dom'
import { Row, Col, Card, Table, Modal, Button, Form, FormControl, FormSelect } from 'react-bootstrap';

import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';
// import { PDFExport } from '@progress/kendo-react-pdf';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import House5 from '../../assets/img/house5.jpg'

function SelectedApartmentRepairs() {
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // getting the id and finding the apparment details
    const { id } = useParams();
    const [apartmentSelected, setApartmentSelected] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [apartmentSelectedRepairs, setApartmentSelectedRepairs] = useState([])
    const [apartmentHouses, setApartmentHouses] = useState([])
    const [landlord, setLandlord] = useState("")


    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // from the localstorage get the logged in user
    useEffect(() => {
        setIsLoading(true)
        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);

                // Create an array to store all the requests
                const requests = landlordIds.map((landlordId) => {
                    return serverUrl
                        .get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => {
                            return res.data.results;
                        })
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                // Wait for all requests to complete
                Promise.all(requests)
                    .then((results) => {
                        // Merge the results into a single array
                        const mergedResults = [].concat(...results);

                        setApartmentSelected(mergedResults.find((apartment) => apartment.id === parseInt(id)));
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
        serverUrl.get(`/landlord/react-repair-house-apartment/?apartment_id=${id}`, config)
            .then((res) => {
                setApartmentSelectedRepairs(res.data.results)
                // setApartmentSelectedRepairs(res.data.results.filter((repairs) => repairs.status === true))
            })
            .catch((error) => {
                console.log(error)
            })
        // get the list of all the houses in the apartment
        serverUrl.get(`/landlord/house/?apartment_id=${id}`, config)
            .then((res) => {
                setApartmentHouses(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

    }, []);

    // searching function
    const [sortType, setSortType] = useState("name");
    const [sortOrder, setSortOrder] = useState("asc");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSort = (sortField) => {
        setSortType(sortField);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        // Reset selected row when user starts a new search
        setSelectedRow(null);
    };

    const sortedData = apartmentSelectedRepairs.sort((a, b) => {
        const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === "asc") {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    const handleRowClick = (item) => {
        setSelectedRow(item);
    };

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };


    // exporting data in excel and in pdf
    const exportCSV = () => {
        const csvData = [
            ['Tenant Name', 'Unit', 'Repair Cost', 'Repair', 'Repair Type'],
            ...apartmentSelectedRepairs.map(item => [item.tenant_name, item.house_name, item.repair_cost, item.repair_name, item.repair_type])
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'apartment_repairs.csv');
    };


    // modal for adding new repair case
    const [showModalHouse, setShowModalHouse] = useState(false)

    const handleOpenModalHouse = () => {
        setShowModalHouse(true);
    };
    const handleCloseModalHouse = () => {
        setShowModalHouse(false);
    };
    // done button reloading the page
    const handleCloseModalReload = () => {
        setShowModalHouse(false);
        window.location.reload()
    };
    // form data to be sent to backend on eadding the Repair
    const [formData, setFormData] = useState({
        description: "",
        amount: "",
        apartment: "",
        house: "",
        posted_by: "",

    });
    // onchange functionality on adding the repair
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    const [apartmentRepair, setApartmentRepair] = useState(null)
    const [repairCategory, setRepairCategory] = useState(null)
    const [repairType, setRepairType] = useState(null)

    // submit of adding a new repair per house
    function handleSubmitHouse(e) {

        // preventing from redirecting to other pages
        e.preventDefault();

        // get user details so that you can use it from the access tokens
        const accessToken = localStorage.getItem("authTokens");

        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {

                const data = {
                    description: formData.description,
                    amount: formData.amount,
                    apartment: id,
                    house: formData.house,
                    posted_by: res.data.results[0].id,
                    repair_category: repairCategory,
                    expense_category: repairType

                };
                //  now post the data
                try {
                    serverUrl

                        //send the Apartment details using the following endpoint
                        .post(`/landlord/repairs-expense/`, data, config)
                        .then((res) => {
                            setFormData({
                                description: "",
                                amount: "",
                                apartment: "",
                                house: "",
                                posted_by: "",
                            });
                            toast.success('Successfully Added a Repair To The Selected Unit!');
                        })

                        // displaying the messages of why the error 
                        .catch((error) => {
                            if (error.response && error.response.status === 400) {
                                alert('Error while adding a new repair' + error.message);
                            } else {
                                toast.error('Unexpected error')
                                console.log('Unexpected error: ' + error.message);
                            }
                        });

                } catch (error) {
                    console.log(error);
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 11) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 11) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/repairs/repairs${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);

    // set is active to false when clicked
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    function handleDelete(repairsId) {
        // update the status of the issue to false
        const data = {
            "is_active": false

        }
        serverUrl.delete(`/landlord/repairs-expense/${repairsId}/`, config)
            .then((res) => {
                console.log(res)
                const updatedActiveIssues = apartmentSelectedRepairs.filter(repair => repair.id !== repairsId);
                setApartmentSelectedRepairs(updatedActiveIssues);
                toast.success('Successfully Deleted the Repair');
                handleModalClose();
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const handleEdit = (repair) => {
        setEditFormData(repair);
        setShowModalEdit(true);
    };

    const handleEditChange = (event) => {
        const { name, value } = event.target;
        setEditFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await serverUrl.put(`/landlord/repairs-expense/${editFormData.id}/`, editFormData, config);
            window.location.reload()
            setApartmentSelectedRepairs((prev) =>
                prev.map((repair) => (repair.id === editFormData.id ? editFormData : repair))
            );
            toast.success('Repair updated successfully!');
            setShowModalEdit(false);
        } catch (error) {
            toast.error('Error updating repair.');
            console.error(error);
        }
    };

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            <div className='repair_apartment'>
                {/* top display of the report */}
                <div className='image_Apartment_report'>
                    {/* <img src={apartmentSelected.image} alt={apartmentSelected.name} /> */}
                    <img src={backgroundImage} alt={apartmentSelected.name} />
                    <p>{apartmentSelected.name} Expenses</p>
                    <span>{apartmentSelected.location}</span>
                </div>
                <Card className='cardProperty d-none'>

                    <Form.Select size='sm' className='formProperty'>
                        <option>By Date</option>
                        <option>This Week</option>
                        <option>This Month</option>
                        <option>This Year</option>
                    </Form.Select>

                    <Form.Select size='sm' className='formProperty'>
                        <option>Type</option>
                        <option>Property</option>
                        <option>Unit</option>

                    </Form.Select>

                    <Form.Select size='sm' className='formProperty'>
                        <option>Unit</option>
                        {apartmentSelectedRepairs.map((defaulter, index) =>
                            <option>{defaulter.house_name}</option>
                        )}
                    </Form.Select>

                    <Form.Select size='sm' className='formProperty'>
                        <option>Tenant Name</option>
                        {apartmentSelectedRepairs.map((defaulter, index) =>
                            <option>{defaulter.tenant_name}</option>
                        )}
                    </Form.Select>



                </Card>


                {/* display the houses that had defaulters */}

                <div className='tasks mt-4' >
                    <Button className='add_repair' onClick={handleOpenModalHouse}>Add Expense</Button>
                    {/* modal used in adding the Expenses of the selected house */}
                    <Modal show={showModalHouse} onHide={handleCloseModalHouse}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add A New Expense</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmitHouse}>
                                {/* category of the repair */}
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <FormSelect className='input-field' name="house" onChange={(e) => setRepairCategory(e.target.value)} value={repairCategory}>
                                        <option disabled selected value="">Select Expense Category</option>
                                        <option value='apartment'> Apartment Expense</option>
                                        <option value='unit'>Unit Expense</option>
                                    </FormSelect>

                                </div>

                                {/* display the units or apartment */}
                                {repairCategory === 'unit' && (
                                    <div className="input-icons">
                                        <i className="fa fa-hashtag icon"></i>
                                        <FormSelect className='input-field' name="house" onChange={handleChange} value={formData.house}>
                                            <option disabled value="">Select Unit</option>

                                            {apartmentHouses.map((house) => (
                                                <option key={house.id} value={house.id}>{house.house_number}</option>
                                            ))}
                                        </FormSelect>

                                    </div>
                                )}
                                 <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <FormSelect className='input-field' name="house" onChange={(e) => setRepairType(e.target.value)} value={repairType}>
                                        <option disabled selected value="">Select Expense Type</option>
                                        <option value='wifi'> WiFi</option>
                                        <option value='repair'>Repair</option>
                                        <option value='water'>Water</option>
                                        <option value='salary'>Salary</option>
                                        <option value='security'>Security</option>
                                    </FormSelect>

                                </div>



                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        placeholder={"Repair Description"}
                                        required
                                    />
                                </div>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="number"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        placeholder={"Repair Amount"}
                                        required
                                    />
                                </div>



                                <Button variant="primary" type="submit" className="nextBtn mt-3" >
                                    Save
                                </Button>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="success" onClick={handleCloseModalReload} >
                                DONE
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <h5 className='text-center fs-3'>Expenses for {apartmentSelected.name}</h5>
                    <div className='d-flex justify-content-between'>
                        <Form className="searchBar bg-white">
                            <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                        </Form>

                        <div>
                            <Button className='btn btn-sm btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
                        </div>

                    </div>

                    <Table className='table mt-3'>
                        <thead className='underline'>
                            <th>House Name</th>
                            <th>Expense</th>
                            <th>Expense Cost</th>
                            <th>Tenant Name</th>
                            <th>Tenant Number</th>
                            <th>Expense Type</th>
                            <th>Expense Category</th>
                            <th>Edit Expense</th>
                            <th>Delete Expense</th>


                        </thead>
                        <tbody>
                            {displayedItems.map((Defaulter, index) =>
                                <tr>
                                    <td>{Defaulter.house_name}</td>
                                    <td>{Defaulter.repair_name}</td>
                                    <td>Ksh. {Defaulter.repair_cost?.toLocaleString()}</td>
                                    <td>{Defaulter.tenant_name}</td>
                                    <td>{Defaulter.tenant_phone}</td>
                                    <td className='text-capitalize'>{Defaulter.repair_type ? Defaulter.repair_type : 'Unit'}</td>
                                    <td className='text-capitalize'>{Defaulter.repair_category}</td>
                                   
                                    <td><i className='bi bi-pen-fill text-primary ' onClick={() => handleEdit(Defaulter)}></i></td>

                                    <td><i className='bi bi-trash-fill text-danger ' onClick={handleModalOpen}></i></td>

                                    <Modal show={showModal} onHide={handleModalClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Repair for House {Defaulter.house_name}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            Are you sure you want to delete this Repair?
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" onClick={() => handleDelete(Defaulter.id)} >
                                                Yes
                                            </Button>
                                            <Button variant="success" onClick={handleModalClose} >
                                                No
                                            </Button>
                                        </Modal.Footer>

                                    </Modal>
                                </tr>

                            )}

                        </tbody>
                    </Table>
                    {/* get the display of the items 5 per page */}
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${apartmentSelectedRepairs.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                    <Modal show={showModalEdit} onHide={() => setShowModalEdit(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Repair</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleEditSubmit}>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        name="description"
                                        value={editFormData.description || ''}
                                        onChange={handleEditChange}
                                        placeholder={"Repair Name"}
                                        required
                                    />
                                </div>

                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="number"
                                        name="repair_cost"
                                        value={editFormData.amount || ''}
                                        onChange={handleEditChange}
                                        placeholder={"Repair Cost"}
                                        required
                                    />
                                </div>

                                <Button type="submit" className="mt-3">
                                    Save Changes
                                </Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
            </div >
        </>
    )
}

export default SelectedApartmentRepairs