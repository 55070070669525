import React, { useState, useEffect, useContext } from 'react';
import './Header.scss';
import { useNavigate } from 'react-router';
import { Form, FormControl, Dropdown, DropdownButton, Image } from 'react-bootstrap';
import User from '../../assets/img/user_sqaure.jpg';
import { BiSun, BiMoon } from 'react-icons/bi';

// auth imports
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import { useTheme } from './ThemeContext';
import { Link } from 'react-router-dom';

function Header(props) {
  // the effects that happen when the sidebar is closed and opened
  const headerStyle = {
    width: props.showSidebar ? 'calc(100% - 250px)' : '100%',
  };

  const [searchQueryAll, setSearchQueryAll] = useState('');
  const [matchingElements, setMatchingElements] = useState([]);
  const pathname = window.location.pathname;
  // const currentUrl = window.location.pathname;
  const hash = window.location.hash;
  const currentUrl = hash.substring(1);

  const handleSearch = () => {
    // Perform your search action here using the searchQueryAll state value
    console.log('Searching for:', searchQueryAll);
    const elements = document.getElementsByClassName('highlight');
    while (elements.length) {
      elements[0].classList.remove('highlight');
    }
    const allElements = document.getElementsByTagName('*');
    const matchingElements = [];
    for (let i = 0; i < allElements.length; i++) {
      const element = allElements[i];
      if (element.textContent.toLowerCase().includes(searchQueryAll.toLowerCase())) {
        matchingElements.push(element);
        element.classList.add('highlight');
      }
    }
    setMatchingElements(matchingElements);
  };

  // for logout purposes
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem('authTokens');
    toast.success('Successfully logged out!');
    navigate('/login');
  };

  // get the user details, including the username
  const [fullName, setFullName] = useState('');
  const [image, setImage] = useState('');
  const [unpaidInvoiceCount, setUnpaidInvoiceCount] = useState(0)
  const [unpaidInvoice, setUnpaidInvoice] = useState([])

  // from the local storage, get the logged-in user
  useEffect(() => {
    const accessToken = localStorage.getItem('authTokens');

    // if not logged in, redirect to login automatically
    if (!accessToken) {
      navigate('/login');
      return;
    }

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token to get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // hitting the endpoint to get the user's details
    serverUrl
      .get(`/users/users-details/${userId}/`, config)
      .then((res) => {
        // get the full name of the user
        setFullName(res.data.full_name);
        setImage(res.data.image);
      })
      .catch((error) => {
        console.log(error);
      });

    // get the landlord id and the unpaid invoices
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the number of invoices not paid
        serverUrl.get(`/landlord/${res.data.results[0].id}/unpaid-invoices`, config)
          .then((res) => {
            setUnpaidInvoiceCount(res.data.unpaid_count)
            setUnpaidInvoice(res.data.unpaid_invoices)
          })
          .catch((error) => {
            console.log(error)
          })

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

// Function to match URL with custom name
const matchUrlWithName = (url) => {
  switch (url) {
    case '':
      return 'Dashboard';
    case 'dashboard':
      return 'Dashboard';
    case 'property':
      return 'Properties';
    case 'bulk':
      return 'Bulk Uploads';
    case 'utilities':
      return 'Utilities';
    case 'reports':
      return 'Reports';
    case 'repairs':
      return 'Repairs';
    case 'messages':
      return 'Communicate';
    case 'profile':
      return 'Profile';
    case 'subscription':
      return 'Subscription';
    case 'selected-property':
      return 'Property Selected';
    default:
      // Split the URL by slashes and retrieve the first two words
      const words = url.split('/').filter((word) => word !== '');

      if (words.length >= 2) {
        // Remove hyphens and digits from the words
        const filteredWords = words
          .map((word) => word.replace(/[-0-9]/g, ' '))
          .filter((word) => word !== '');

        return filteredWords.join(' ');
      } else {
        return url;
      }
  }
};

// Function to clean and transform URL
const cleanUrl = (currentUrl) => {
  // Remove everything after '?' if it exists
  const baseUrl = currentUrl.includes('?') ? currentUrl.split('?')[0] : currentUrl;
  
  // Replace hyphens with spaces
  return baseUrl.replaceAll('-', ' ');
};

// Example usage
const cleanUrlName = cleanUrl(currentUrl);
const currentUrlName = matchUrlWithName(cleanUrlName.slice(1));


  const goBack = () => {
    window.history.back();
  };


  // Check if the current URL is "profile" or "dashboard"
  const hideBackArrow = (currentUrl.slice(1)) === 'utilities' || (currentUrl.slice(1)) === 'property' || (currentUrl.slice(1)) === 'reports' || (currentUrl.slice(1)) === 'repairs' || (currentUrl.slice(1)) === 'profile' || (currentUrl.slice(1)) === 'dashboard';
  const { theme, toggleTheme } = useTheme();

  // State for managing notification animation
  const [showNotification, setShowNotification] = useState(false);

  // Function to handle notification click
  const handleNotificationClick = () => {
    // Toggle notification visibility
    setShowNotification(!showNotification);
  };

  // useEffect to control the notification flickering
  useEffect(() => {
    // Flicker the notification icon every second
    const interval = setInterval(() => {
      setShowNotification((prev) => !prev);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // useEffect to hide notification after 30 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNotification(false);
    }, 30000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`headerDashboard-${theme}`} style={headerStyle}>
      <div className="left">
        {!hideBackArrow && (
          <i
            className="bi bi-arrow-left-circle-fill"
            onClick={goBack}
            style={{ fontSize: '1.5rem', color: '#1F57F8' }}
          ></i>
        )}

        <span className="text-uppercase">{currentUrlName}</span>
      </div>

      <div className="right">
        {/* <button onClick={toggleTheme} className="btn-toggle-theme btn-transparent border-0 bg-transparent">
          {theme === 'light' ? <BiMoon /> : <BiSun className='text-white' />}
        </button> */}
        {/* display the unpaid invoices */}


        {unpaidInvoiceCount > 0 && (
          <Link to='/unpaid-invoice' className='text-white text-decoration-none mt-3'>
            <button type="button" class="btn btn-primary position-relative" style={{ height: "fit-content", alignSelf: "center" }}>

              Unpaid Invoices
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {unpaidInvoiceCount}
              </span>
            </button>
          </Link>

          // <Link className='text-decoration-none p-2 btn btn-sm btn-info text-white'>

          //   Unpaid Invoices <span className='badge ' >{unpaidInvoiceCount}</span>
          // </Link>
        )}


        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className="profile">
            <Image src={image} className="userImage" />
            {fullName}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/profile" className="text-muted border-bottom">
              <i className="bi bi-person me-2"></i>Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout} className="text-muted">
              <i className="bi bi-box-arrow-left me-2"></i>Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <i className={`bi bi-bell-fill text-primary fs-4  ms-2 align-self-center ${showNotification ? 'flicker' : ''}`} onClick={handleNotificationClick}></i>
        {showNotification && (
          <span className='notificationDot'></span>
        )} */}
      </div>
    </div >
  );
}

export default Header;
